import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native'

import 'firebase/firestore';

import HamburgerIcon from '../containers/HambugerIcon';
import HeaderWeb from '../containers/HeaderWeb';

import Landing from '../landing/Landing'
import { colors } from '../constants/colors';
import { SafeAreaView } from 'react-native-web';


function OpenDrawer() {
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.toggleDrawer()
      }}
    >
      <HamburgerIcon />
    </TouchableOpacity>
  )
}

function LandingScreen() {

  return (
    <View style={styles.main}>
      <View style={styles.container}>
          <View style={styles.header}> 
            <OpenDrawer />
            <HeaderWeb />
          </View>
      </View>
      
      <Landing style={styles.landing} />
    </View>
  )
}

export default LandingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
    zIndex: 100,
    position: 'absolute'
  },
  main: {
    flexGrow: 1,
    backgroundColor: colors.white,
  },
  header: { 
    flex: 1, 
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  landing: {
    zIndex: 0,
  },
  text: {
    alignItems: 'center',
    textAlign: "center",
  }
});
