import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import HeaderWeb from "../containers/HeaderWeb";
import HamburgerIcon from "../containers/HambugerIcon";
import { colors } from "../constants/colors";
import Footer from "../landing/Components/Footer";

function OpenDrawer() {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.toggleDrawer();
      }}
    >
      <HamburgerIcon />
    </TouchableOpacity>
  );
}

const BookRoomsScreen = () => {
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <OpenDrawer />
          <HeaderWeb />
        </View>
      </View>

      <ScrollView style={styles.contentContainer}>
        <View style={styles.section}>
          <div className="image-wrapper">
            <ImageBackground
              source={require("../assets/sevenStarsFront.png")}
              style={styles.backgroundImage}
            >
              <div className="overlay"></div>
            </ImageBackground>
            <div className="carousel-caption d-md-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="hero-heading">Book Room</h1>
              </div>
            </div>
          </div>
        </View>

        <div className="section-images no-margin-padding">
          <div className="no-gutter-row building-section">
            <div className="col-md-6 text-container-top">
              <div className="text-content-top">
                <h2 className="main-title-heading">Rooms</h2>
                <p>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Magnis
                  nostra sem nec tortor fusce commodo elementum luctus felis.
                  Quisque aptent sagittis vestibulum vulputate volutpat vivamus
                  tellus. Vulputate ultricies iaculis sapien montes sed nunc.
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Magnis
                  nostra sem nec tortor fusce commodo elementum luctus felis.
                  Quisque aptent sagittis vestibulum vulputate volutpat vivamus
                  tellus. Vulputate ultricies iaculis sapien montes sed nunc.
                </p>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="image-wrapper-bottom">
                <iframe
                  title="Booking.com iframe"
                  src="https://www.booking.com/hotel/gb/the-seven-stars-durham.en-gb.html?aid=356980&label=gog235jc-1FCAsoUEIWdGhlLXNldmVuLXN0YXJzLWR1cmhhbUgJWANoUIgBAZgBCbgBF8gBDNgBAegBAfgBAogCAagCA7gCspmEvAbAAgHSAiRjOTI0ZTc0Ny1iNDUxLTRjMDUtYmYzOC0wNThiYWVjMzdhNDPYAgXgAgE&sid=ce34ed6c08bdee3dee50168645d7f7df&dest_id=-2594983&dest_type=city&dist=0&group_adults=2&group_children=0&hapos=1&hpos=1&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA&sb_price_type=total&sr_order=popularity&srepoch=1736510681&srpvid=26dd54d9c8330480&type=total&ucfs=1&"
                  width="100%"
                  height="450"
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
    maxWidth: 1280,
    zIndex: 100,
    position: "absolute",
  },
  header: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: "#fffaf4",
  },
  section: {
    height: 400,
    position: "relative",
  },
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  overlayContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  content: {
    padding: 20,
  },
  roomInfo: {
    marginTop: 20,
  },
  roomHeading: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 10,
  },
  roomDescription: {
    fontSize: 16,
    color: "#333",
    lineHeight: 22,
  },
});

export default BookRoomsScreen;
