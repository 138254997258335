import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  ImageBackground,
  Image,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useNavigation } from "@react-navigation/native";
import HeaderWeb from "../containers/HeaderWeb";
import HamburgerIcon from "../containers/HambugerIcon";
import { colors } from "../constants/colors";
import Footer from "../landing/Components/Footer";

function OpenDrawer() {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.toggleDrawer();
      }}
    >
      <HamburgerIcon />
    </TouchableOpacity>
  );
}

const BookTablesScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const generateDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 365; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(date.toISOString().split("T")[0]);
    }
    return dates;
  };

  const generateTimeSlots = () => {
    const times = [];
    for (let hour = 10; hour <= 21; hour++) {
      times.push(`${hour.toString().padStart(2, "0")}:00`);
      times.push(`${hour.toString().padStart(2, "0")}:30`);
    }
    return times;
  };

  const onSubmit = async () => {
    if (!name || !email || !selectedDate || !selectedTime) {
      Alert.alert("Error", "Please fill in all fields");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phoneNumber);
    formData.append("date", selectedDate);
    formData.append("time", selectedTime);
    formData.append("message", message);
    formData.append("access_key", "317f8cb5-5ca1-4389-aa1c-c7cdce89ff42");

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(Object.fromEntries(formData)),
      });

      const jsonRes = await res.json();

      if (jsonRes.success) {
        Alert.alert("Success", "Your message has been sent!");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        Alert.alert("Error", "Failed to send message");
      }
    } catch (error) {
      console.error("Submission error:", error);
      Alert.alert("Error", "An error occurred while sending the message");
    }
  };
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <OpenDrawer />
          <HeaderWeb />
        </View>
      </View>
      <ScrollView style={styles.contentContainer}>
        <View style={styles.section}>
          <div className="image-wrapper">
            <ImageBackground
              source={require("../assets/sevenStarsTable.png")}
              style={styles.backgroundImage}
            >
              <div className="overlay"></div>
            </ImageBackground>
            <div className="carousel-caption d-md-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="hero-heading">Book Table</h1>
              </div>
            </div>
          </div>
        </View>
        <div className="section-images no-margin-padding">
          <div className="no-gutter-row building-section">
            <div className="col-md-6 text-container-top">
              <div className="text-content-top">
                <h2 className="main-title-heading">Book Table</h2>
                <p>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Magnis
                  nostra sem nec tortor fusce commodo elementum luctus felis.
                  Quisque aptent sagittis vestibulum vulputate volutpat vivamus
                  tellus. Vulputate ultricies iaculis sapien montes sed nunc.
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Magnis
                  nostra sem nec tortor fusce commodo elementum luctus felis.
                  Quisque aptent sagittis vestibulum vulputate volutpat vivamus
                  tellus. Vulputate ultricies iaculis sapien montes sed nunc.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-container-top">
              <div className="image-wrapper-bottom">
                <View style={styles.formContainer}>
                  <h2 className="main-title-heading">Request a table</h2>
                  <TextInput
                    style={styles.input}
                    placeholder="Your Name"
                    placeholderTextColor="#78797a"
                    value={name}
                    onChangeText={setName}
                  />
                  <TextInput
                    style={styles.input}
                    placeholder="Phone Number"
                    placeholderTextColor="#78797a"
                    keyboardType="numeric"
                    value={phoneNumber}
                    onChangeText={(text) => {
                      // User can only type numbers
                      const filteredText = text.replace(/[^0-9]/g, "");
                      setPhoneNumber(filteredText);
                    }}
                  />
                  <Picker
                    selectedValue={selectedDate}
                    onValueChange={(itemValue) => setSelectedDate(itemValue)}
                    style={[styles.input, styles.pickerStyle]}
                  >
                    <Picker.Item label="Select Date" value="" />
                    {generateDates().map((date) => (
                      <Picker.Item key={date} label={date} value={date} />
                    ))}
                  </Picker>

                  <Picker
                    selectedValue={selectedTime}
                    onValueChange={(itemValue) => setSelectedTime(itemValue)}
                    style={[styles.input, styles.pickerStyle]}
                  >
                    <Picker.Item label="Select Time" value="" />
                    {generateTimeSlots().map((time) => (
                      <Picker.Item key={time} label={time} value={time} />
                    ))}
                  </Picker>
                  <TextInput
                    style={styles.input}
                    placeholder="Your Email"
                    placeholderTextColor="#78797a"
                    keyboardType="email-address"
                    value={email}
                    onChangeText={setEmail}
                  />
                  <TextInput
                    style={[styles.input, styles.textarea]}
                    placeholder="Any additional notes including how many seats"
                    placeholderTextColor="#78797a"
                    multiline
                    numberOfLines={4}
                    value={message}
                    onChangeText={setMessage}
                  />
                  <TouchableOpacity
                    style={styles.submitButton}
                    onPress={onSubmit}
                  >
                    <Text style={styles.submitButtonText}>Submit</Text>
                  </TouchableOpacity>
                </View>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
    maxWidth: 1280,
    zIndex: 100,
    position: "absolute",
  },
  header: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: "#fffaf4",
  },
  section: {
    height: 400,
    position: "relative",
  },
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  content: {
    padding: 20,
  },
  pickerStyle: {
    paddingLeft: 5,
  },
  formContainer: {
    padding: 20,
    backgroundColor: "#fffaf4",
  },
  input: {
    backgroundColor: "#fffaf4",
    borderWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    marginBottom: 15,
    borderRadius: 5,
    fontSize: 16,
    color: "#78797a",
    fontFamily: "Inter",
    fontWeight: "400",
    height: 42,
    paddingVertical: 0,
    justifyContent: "center",
    textAlign: "left",
  },
  textarea: {
    minHeight: 100,
    paddingVertical: 10,
    height: undefined,
  },
  submitButton: {
    backgroundColor: "#9b6e5a",
    padding: 15,
    borderRadius: 20,
    alignItems: "center",
  },
  submitButtonText: {
    color: "#fffaf4",
    fontSize: 18,
    fontWeight: "500",
  },
});
export default BookTablesScreen;
