import React, { useState } from "react";
import "../styles.css";
import {
    Image
} from "react-native";
import { useNavigation } from "@react-navigation/native";

function BookTables() {
    const navigation = useNavigation();

    const handleBookTableClick = () => {
        navigation.navigate("Book Tables"); // Navigate to the Book Tables screen
    };

    return (
        <div className="section-images no-margin-padding">
            <div className="row no-gutters no-gutter-row ">
                <div className="col-md-6 text-container">
                    <div className="text-content">
                        <h2 className="main-title-heading">Tables</h2>
                        <p>
                            Lorem ipsum odor amet, consectetuer adipiscing elit.
                            Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                            Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                            Vulputate ultricies iaculis sapien montes sed nunc.Lorem ipsum odor amet, consectetuer adipiscing elit.
                            Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                            Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                            Vulputate ultricies iaculis sapien montes sed nunc.
                        </p>
                        <button
                            type="button"
                            className="hero-button-container"
                            onClick={handleBookTableClick} // Trigger navigation to Book Tables screen
                        >
                            Book Table
                        </button>
                    </div>
                </div>
                <div className="col-md-6 image-container">
                    <Image
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        source={require("../../assets/sundaydinner.png")}
                    />
                </div>
            </div>
        </div>
    );
}

export default BookTables;
