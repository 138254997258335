import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigation } from "@react-navigation/native";
import Carousel from "./Components/Carousel";
import Maps from "./Components/Maps";
import Footer from "./Components/Footer";
import About from "./Components/About";
import BuildingPic from "./Components/BuildingPic";
import BookTables from "./Components/BookTables";
import BookRooms from "./Components/BookRooms";
import BookTablesScreen from "../screens/BookTablesScreen";
import ContactUsScreen from "../screens/ContactUsScreen";
import BookRoomsScreen from "../screens/BookRoomsScreen";

export default function Landing() {
  const navigation = useNavigation()

  return (
    <div class="App">
      <Carousel />
      <BuildingPic />
      <BookTables />
      <BookRooms />
      <Maps />
      <Footer />
    </div>

  );
}
