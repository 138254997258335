import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  ImageBackground,
  Image,
  TouchableOpacity,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import HeaderWeb from "../containers/HeaderWeb";
import HamburgerIcon from "../containers/HambugerIcon";
import { colors } from "../constants/colors";
import Footer from "../landing/Components/Footer";

function OpenDrawer() {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.toggleDrawer();
      }}
    >
      <HamburgerIcon />
    </TouchableOpacity>
  );
}

const ContactUsScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async () => {
    if (!name || !email || !message) {
      Alert.alert("Error", "Please fill in all fields");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("access_key", "317f8cb5-5ca1-4389-aa1c-c7cdce89ff42");

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(Object.fromEntries(formData)),
      });

      const jsonRes = await res.json();

      if (jsonRes.success) {
        Alert.alert("Success", "Your message has been sent!");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        Alert.alert("Error", "Failed to send message");
      }
    } catch (error) {
      console.error("Submission error:", error);
      Alert.alert("Error", "An error occurred while sending the message");
    }
  };
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <OpenDrawer />
          <HeaderWeb />
        </View>
      </View>
      <ScrollView style={styles.contentContainer}>
        <View style={styles.section}>
          <div className="image-wrapper">
            <ImageBackground
              source={require("../assets/tablesRoom.png")}
              style={styles.backgroundImage}
            >
              <div className="overlay"></div>
            </ImageBackground>
            <div className="carousel-caption d-md-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="hero-heading">Contact Us</h1>
              </div>
            </div>
          </div>
        </View>
        <>
          <div className="section-images no-margin-padding">
            <div className="no-gutter-row building-section">
              <div className="col-md-6 text-container-top">
                <div className="text-content-top">
                  <h2 className="main-title-heading">Contact Us</h2>
                  <p>
                    Lorem ipsum odor amet, consectetuer adipiscing elit. Magnis
                    nostra sem nec tortor fusce commodo elementum luctus felis.
                    Quisque aptent sagittis vestibulum vulputate volutpat
                    vivamus tellus. Vulputate ultricies iaculis sapien montes
                    sed nunc. Lorem ipsum odor amet, consectetuer adipiscing
                    elit. Magnis nostra sem nec tortor fusce commodo elementum
                    luctus felis. Quisque aptent sagittis vestibulum vulputate
                    volutpat vivamus tellus. Vulputate ultricies iaculis sapien
                    montes sed nunc.
                  </p>
                </div>
              </div>
              <div className="col-md-6 text-container-top">
                <div className="image-wrapper-bottom">
                  <View style={styles.formContainer}>
                    <h2 className="main-title-heading">
                      Send us a message
                    </h2>
                    <TextInput
                      style={styles.input}
                      placeholder="Your Name"
                      value={name}
                      onChangeText={setName}
                    />
                    <TextInput
                      style={styles.input}
                      placeholder="Your Email"
                      keyboardType="email-address"
                      value={email}
                      onChangeText={setEmail}
                    />
                    <TextInput
                      style={[styles.input, styles.textarea]}
                      placeholder="Your Message"
                      multiline
                      numberOfLines={4}
                      value={message}
                      onChangeText={setMessage}
                    />
                    <TouchableOpacity
                      style={styles.submitButton}
                      onPress={onSubmit}
                    >
                      <Text style={styles.submitButtonText}>Submit</Text>
                    </TouchableOpacity>
                  </View>
                </div>
              </div>
            </div>
          </div>
        </>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  main: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
    maxWidth: 1280,
    zIndex: 100,
    position: "absolute",
  },
  contentContainer: {
    flex: 1,
    backgroundColor: "#fffaf4",
  },
  section: {
    height: 400,
    position: "relative",
  },
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  formContainer: {
    padding: 20,
    backgroundColor: "#fffaf4",
  },
  input: {
    backgroundColor: "#fffaf4",
    borderWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    marginBottom: 15,
    borderRadius: 5,
    fontSize: 16,
    color: "#78797a",
    fontFamily: "Inter",
    fontWeight: "400",
    height: 42,
    paddingVertical: 0,
    justifyContent: "center",
    textAlign: "left",
  },
  textarea: {
    height: 120,
    textAlignVertical: "top",
  },
  submitButton: {
    backgroundColor: "#9b6e5a",
    padding: 15,
    borderRadius: 20,
    alignItems: "center",
  },
  submitButtonText: {
    color: "#fffaf4",
    fontSize: "18px",
    fontWeight: "500",
  },
});

export default ContactUsScreen;
