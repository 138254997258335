import React from "react";
import "../styles.css";
import { Image } from "react-native";

function BuildingPic() {
    return (
        <>
            <div className="section-images no-margin-padding">
                <div className="row no-gutters no-gutter-row flip-image">
                    <div className="col-md-6 image-container">
                        <div className="image-wrapper">
                            <Image
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                source={require('../../assets/sevenStarsFront.png')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 text-container">
                        <div className="text-content">
                            <h2 className="main-title-heading">About Us</h2>
                            <p> Lorem ipsum odor amet, consectetuer adipiscing elit.
                                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                                Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                                Vulputate ultricies iaculis sapien montes sed nunc.Lorem ipsum odor amet, consectetuer adipiscing elit.
                                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                                Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                                Vulputate ultricies iaculis sapien montes sed nunc. Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                                Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default BuildingPic;