import React from "react";
import { Image } from "react-native";
import { useNavigation } from '@react-navigation/native';

function BookRooms() {
  const navigation = useNavigation();

  const handleBookRoomsClick = () => {
    navigation.navigate("Book Rooms"); // Navigate to the Book Rooms screen
  };

  return (
    <>
      <div className="section-images no-margin-padding">
        <div className="row no-gutters no-gutter-row flip-image">
          <div className="col-md-6 image-container">
            <div className="image-wrapper">
            <Image
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              source={require('../../assets/room.png')}
              />
              </div>
          </div>
          <div className="col-md-6 text-container">
            <div className="text-content">
              <h2 className="main-title-heading">Rooms</h2>
              <p>Lorem ipsum odor amet, consectetuer adipiscing elit.
                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                Vulputate ultricies iaculis sapien montes sed nunc. Lorem ipsum odor amet, consectetuer adipiscing elit.
                Magnis nostra sem nec tortor fusce commodo elementum luctus felis.
                Quisque aptent sagittis vestibulum vulputate volutpat vivamus tellus.
                Vulputate ultricies iaculis sapien montes sed nunc.</p>
              <button
                type="button"
                className="hero-button-container"
                onClick={handleBookRoomsClick} // Trigger navigation to Book Rooms screen
              >
                Book Room
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookRooms;
