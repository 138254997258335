import React from "react";
import { useNavigation } from "@react-navigation/native";
import "../styles.css";

function Maps() {
  const navigation = useNavigation(); // Hook to access navigation

  const handleContactUsClick = () => {
    navigation.navigate("Contact Us"); // Navigate to the ContactUs screen
  };

  return (
    <div className="section-images no-margin-padding">
      <div className="row no-gutters no-gutter-row">
        <div className="col-md-6 text-container">
          <div className="text-content">
            <h2 className="main-title-heading">Find Us</h2>
            <p>High Street North, <br />Durham, DH1 2NU</p>
            <br />
            <br />
            <br />
            <h2 className="main-title-heading">Hours</h2>
            <p>
              Monday-Sunday<br />12pm – 11pm<br />
            </p>
          </div>
        </div>
        <div className="col-md-4 image-wrapper-bottom-map">
          <iframe
            className="map"
            title="Seven Stars Inn's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2302.1187188598733!2d-1.5493562231808515!3d54.76029937273299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e80b4303ca70d%3A0x22bc3b88d3eb8fe1!2sSeven%20Stars%20Inn!5e0!3m2!1sen!2suk!4v1731002543597!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Maps;
