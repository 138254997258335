import React from "react";
import hero1 from "../../assets/1.webp";
import hero2 from "../../assets/2.webp";
import hero3 from "../../assets/3.webp";
import hero4 from "../../assets/4.webp";
import { useNavigation } from "@react-navigation/native";

import "../styles.css";

function Carousel() {
  const navigation = useNavigation();

  const handleBookTableClick = () => {
    navigation.navigate("Book Tables"); // Navigate to the Book Tables screen
  };

  const handleBookRoomsClick = () => {
    navigation.navigate("Book Rooms"); // Navigate to the Book Rooms screen
  };
  return (
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={hero1} className="cImg d-block w-100" alt="Cover Image 1" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero2} className="cImg d-block w-100" alt="Cover Image 2" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero3} className="cImg d-block w-100" alt="Cover Image 3" />
          <div className="overlay"></div>
        </div>
        <div className="carousel-item">
          <img src={hero4} className="cImg d-block w-100" alt="Cover Image 4" />
          <div className="overlay"></div>
        </div>
      </div>

      {/* Common carousel caption */}
      <div className="carousel-caption d-md-flex justify-content-center align-items-center">
  <div className="text-center">
    <h1 className="hero-heading">Pub</h1>
    <h1 className="hero-heading">Kitchen</h1>
    <h1 className="hero-heading">Rooms</h1>
  </div>

  {/* New container for buttons */}
  <div className="button-container">
    <button
      type="button"
      className="hero-button-carousel"
      onClick={handleBookTableClick} // Trigger navigation to Book Tables screen
    >
      Book Table
    </button>
    <button
      type="button"
      className="hero-button-carousel"
      onClick={handleBookRoomsClick} // Trigger navigation to Book Rooms screen
    >
      Book Room
    </button>
  </div>
</div>


      {/* Carousel controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
